<!-- 视频页 -->
<template>
<div class="videoDetail">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter">
        <div class="content">
            <div class="video" v-if="config.c1&&config.c1.isShow">
                <video controls>
                    <source :src="config.c1.src" type="video/mp4" />可能您的浏览器偏老，不支持HTML5 video 标签，请使用现代浏览器，如chrome等。
                </video>
                <p class="text textV">{{config.c1.title}}</p>
            </div>
            <div v-if="config.c2&&config.c2.isShow">
				<p class="title">{{config.c2.title}}</p>
                <p class="text" v-for="(item, index) in config.c2.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: '',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'video/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.videoDetail {
    .content {
        margin-top: 0.3rem;
        margin-bottom: .3rem;

        @media (max-width: 767px) {
            margin: 0.1rem 0 0;
        }

        .video {
            width: 10rem;
            margin:0 auto .3rem;
			text-align: center;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 0.5rem;
            }

            video {
                width: 10rem;
                margin-left: auto;
				margin-right:auto;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .title {
            color: #333333;
            font-size: 0.26rem;
            font-weight: bold;
			margin: 0.3rem 0;
        }

        .text {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            text-align: justify;
			&.textV{
				margin:.3rem auto 0;
			}
        }
    }
}
</style>
